<template>
	<div class="app-box maps-box" :class="{ full: full }">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">地图管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" @click="full = !full">
						{{ full ? '退出全屏' : '全屏打点' }}
					</a-button>
				</a-form-model-item>
				<a-form-model-item label="项目">
					<a-select v-model="query.factory_class" class="a-select" placeholder="请选择类型"
						@change="handleChangeFactory">
						<a-select-option :value="2">
							{{ '大理卷烟厂主厂区' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '大理卷烟厂复烤厂' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '大理卷烟厂斜阳库' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="分类">
					<a-select v-model="query.class_type" class="a-select" placeholder="请选择类型"
						@change="mainqueryclassChange">
						<a-select-option :value="1">
							{{ '消防' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '监控' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '门禁' }}
						</a-select-option>
						<a-select-option :value="4">
							{{ '网络设备' }}
						</a-select-option>
					</a-select>
					<!-- {{ lc_label }} -->
				</a-form-model-item>
				<a-form-model-item label="二级分类">
					<a-select class="a-select" placeholder="请先选择分类" @change="mainquerytypeChange"
						v-model="query.device_type_name">
						<a-select-option :value="item.device_type_name" v-for="item in typelsit"
							:key="item.device_type_name">
							{{ item.device_type_name }}
						</a-select-option>
					</a-select>
					<!-- {{ lc_label }} -->
				</a-form-model-item>
				<a-form-model-item label="显示等级">
					<a-select class="a-select" @change="chageLevel" placeholder="默认显示全部" v-model="query.level">
						<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11" :key="((count + 33) * 0.5)">
							{{ ((count + 33) * 0.5) }}级
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<div class="fengmap-box">
			<div id="fengmap" @click="weituo"></div>
		</div>


		<a-drawer title="关联设备" placement="right" width="750" :closable="false" :visible="listvisible" @close="listonClose">
			<a-form-model class="form-right" :colon="false" layout="inline" :model="listquery" labelAlign="left"
				@submit="handleSearchNotBind" @submit.native.prevent>
				<a-row>
					<a-form-model-item label="当前位置:">
						{{ bindObj.factory }}>{{ bindObj.buildingName }}>{{ bindObj.floor }}
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-form-model-item label="坐标:">
						经度：{{ wz.lon }} 纬度：{{ wz.lat }}
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-form-model-item style="float: right; margin-right: 0">
						<a-button type="primary" html-type="submit" size="small">
							查询
						</a-button>
						<a-button @click="Reset" size="small" style="margin-left: 10px;">重置</a-button>
					</a-form-model-item>
					<a-form-model-item label="设备类型">
						<a-select v-model="listquery.class_type" class="a-select" placeholder="请选择类型" size="small"
							@change="handleChange">
							<a-select-option :value="1">
								{{ '消防' }}
							</a-select-option>
							<a-select-option :value="2">
								{{ '监控' }}
							</a-select-option>
							<a-select-option :value="3">
								{{ '门禁' }}
							</a-select-option>
							<a-select-option :value="4">
								{{ '网络设备' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="设备名称">
						<a-input v-model="listquery.device_name" placeholder="设备名称" size="small" />
					</a-form-model-item>
					<a-form-model-item label="设备编号">
						<a-input v-model="listquery.device_number" placeholder="设备编号" size="small" />
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-tabs v-model="listquery.bind_type" @change="callback">
						<a-tab-pane :key="1" tab="待添加">
						</a-tab-pane>
						<a-tab-pane :key="2" tab="已添加" force-render>
						</a-tab-pane>
						<a-tab-pane :key="3" tab="全部">
						</a-tab-pane>
					</a-tabs>
				</a-row>
			</a-form-model>
			<a-table class="main-table" :pagination="{
				current: listquery.page,
				total: pagetotal
			}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
				<span slot="class_type" slot-scope="text, record">
					{{ text == 1 ? '消防' : (text == 2 ? '监控' : (text == 3 ? '门禁' : '网络设备')) }}
				</span>
				<span slot="icon" slot-scope="text, record">
					<img :src="record.icon" class="img-qr">
				</span>
				<span slot="action" slot-scope="text, record">
					<a size="small" v-if="record.is_bind == -1" @click="bind(record)">添加</a>
					<a-row v-if="record.is_bind == 1">
						<a style="color: #61656E" disabled="disabled">已添加</a>&nbsp;&nbsp;&nbsp;
						<a-popconfirm title="确认删除该设备" ok-text="是" cancel-text="否" @confirm="notBind(record)"
							style="color: #000000">
							<a size="small">删除</a>
						</a-popconfirm>
					</a-row>
				</span>
			</a-table>
		</a-drawer>

		<a-drawer title="编辑设备" placement="right" width="750" :closable="false" :visible="infovisible" @close="onClose">
			<a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="dictionaryForm" :model="form">
				<a-form-model-item label="所在位置">
					<template v-if="form.is_bind == 1">{{ factory_label[form.factory_class] }}>{{
						form.floor_id
					}}</template>
					<template v-else>暂未绑定</template>
				</a-form-model-item>
				<a-form-model-item prop="class_type" label="设备类型">
					<a-select v-model="form.class_type" class="a-select" placeholder="请选择类型" @change="handleChangeEdit">
						<a-select-option :value="1">
							{{ '消防' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '监控' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '门禁' }}
						</a-select-option>
						<a-select-option :value="4">
							{{ '网络设备' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="二级类型">
					<a-select class="a-select" placeholder="请先选择分类" v-model="form.dictionary_id">
						<a-select-option :value="item.dictionary_id" v-for="item in typelsit" :key="item.dictionary_id">
							{{ item.device_type_name }}
						</a-select-option>
					</a-select>
					<!-- {{ lc_label }} -->
				</a-form-model-item>
				<a-form-model-item prop="device_number" label="设备编号">
					<a-input v-model="form.device_number" disabled></a-input>
				</a-form-model-item>
				<a-form-model-item v-if="form.dictionary_id === '39'" prop="device_number" label="旋转角度">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<div class="dir-box">
						<!-- <img src="" alt="" class="img-dir-icon"> -->
						<img src="@/assets/guang.png" class="img-dir" :style="`transform: rotate(${form.direction}deg);`">
					</div>
					<a-row :gutter="16">
						<a-col :span="12">
							<a-slider v-model="form.direction" :min="0" :max="360" :disabled="form.is_direction == -1" />
						</a-col>
						<a-col :span="4">
							<a-input-number v-model="form.direction" :min="0" :max="360"
								:disabled="form.is_direction == -1" />
						</a-col>
						<a-switch :checked="form.is_direction == 1" @change="directionOnChange" />
						展示角度
					</a-row>
				</a-form-model-item>
				<a-form-model-item prop="device_type_name" label="展示级别">
					<a-select v-model="form.low_show" style="width: 120px" :disabled="isDo">
						<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11" :key="((count + 33) * 0.5)">
							{{ ((count + 33) * 0.5) }}
						</a-select-option>
					</a-select>
					<span class="ant-form-text">
						<a style="color: #D9D9D9">—</a>
					</span>
					<a-select v-model="form.high_show" style="width: 120px" :disabled="isDo">
						<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11" :key="((count + 33) * 0.5)">
							{{ ((count + 33) * 0.5) }}
						</a-select-option>
					</a-select>
					<a-switch :checked="form.warn_show == 1" @change="warnChange" :disabled="isDo">
						<a-icon slot="checkedChildren" type="check" />
						<a-icon slot="unCheckedChildren" type="close" />
					</a-switch>
					仅报警显示
					<a-switch :checked="form.is_customize == 1" @change="onlyOnChange" />
					自定义编辑
				</a-form-model-item>
				<a-form-model-item prop="device_name" label="设备名称">
					<a-input v-model="form.device_name" placeholder="请输入设备名"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="lon" label="经度">
					<a-input v-model="form.lon" placeholder="请输入经度" disabled></a-input>
				</a-form-model-item>
				<a-form-model-item prop="lat" label="纬度">
					<a-input v-model="form.lat" placeholder="请输入纬度" disabled></a-input>
				</a-form-model-item>
				<a-form-model-item prop="device_number" label="设备报警">
					<a-radio-group name="radioGroup" v-model="form.warn_type">
						<a-radio :value="1">
							语音加页面报警
						</a-radio>
						<a-radio :value="2">
							页面报警
						</a-radio>
						<a-radio :value="3">
							不报警
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item prop="remark" label="位置描述">
					<a-textarea v-model="form.remark" placeholder="请输入位置描述">
					</a-textarea>
				</a-form-model-item>
			</a-form-model>
			<div :style="{
				position: 'absolute',
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e8e8e8',
				padding: '10px 16px',
				textAlign: 'right',
				left: 0,
				background: '#fff',
				borderRadius: '0 0 4px 4px',
			}">
				<a-button style="marginRight: 8px" @click="onClose">
					取消
				</a-button>
				<a-button style="marginRight: 8px" type="primary" @click="submitInfo">
					提交
				</a-button>
				<a-popconfirm title="确认删除该设备" ok-text="是" cancel-text="否" @confirm="notBind(form)" style="color: #000000">
					<a-button type="danger">
						删除
					</a-button>
				</a-popconfirm>
			</div>
		</a-drawer>

	</div>
</template>

<script>


import fengmap from 'fengmap/build/fengmap.map.min.js'
import 'fengmap/build/fengmap.plugin.min.js'
import {
	domain
} from '@/siteInfo.js'
import {
	get
} from 'js-cookie'

export default {
	data() {
		return {
			full: false,
			factory_label: {
				'1': '大理卷烟厂复烤厂',
				'2': '大理卷烟厂主厂区',
				'3': '大理卷烟厂斜阳库'
			},
			floor_label: {
				'1': 'F1',
				'2': 'F2',
				'3': 'M',
				'4': 'F3',
				'5': 'F4',
				'6': 'F5'
			},
			markerSz: {},
			query: {
				// floor_id: 1,
				limit: 9999,
				factory_class: 2
			},
			listquery: {
				page: 1,
				limit: 10,
				bind_type: 1
			},
			bindObj: {
				factory: '',
				buildingID: '',
				buildingName: '',
				floor: '',
			},
			customer_id: null,
			pagetotal: 0,
			checkValue: [],
			data: [],
			selectedRowKeys: [],
			markers: [],
			jzlist: [],
			lclist: [],
			typelsit: [],
			show: false,
			factory_id: '1',
			lc_label: 'F1',
			visible: false,
			infovisible: false,
			listvisible: false,
			form: {},
			columns: [{
				title: '设备类型',
				dataIndex: 'class_type',
				scopedSlots: {
					customRender: 'class_type'
				}
			},
			// {
			// 	title: '设备类型',
			// 	dataIndex: 'device_type_name'
			// },
			{
				title: '设备名称',
				dataIndex: 'device_name'
			},
			{
				title: '设备编号',
				dataIndex: 'device_number'
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				},
				// width: "20%"
			}
			],
			wz: {
				lon: '',
				lat: ''
			}
		}
	},
	created() {

	},
	mounted() {
		const options = {
			appName: '大理卷烟厂复烤厂',
			key: '48874e0607f3c1517b7bc752ee37ed81',
			mapID: '1584485960495415298',
			mapURL: './map/',
			themeURL: './theme/',
			container: document.getElementById('fengmap'),
			themeID: '1588152997390413826',
			viewMode: fengmap.FMViewMode.MODE_2D,
			center: {
				x: 11158376.451710911,
				y: 2948319.80990674
			}
		}
		this.map = new fengmap.FMMap(options)
		this.map.on('click', event => {
			let buildingID
			let build
			let lclist
			let level
			if (this.map.getZoom() >= 20) {
				buildingID = this.buildingID
			} else {
				buildingID = ''
			}
			if (buildingID.length > 0) {
				build = this.map.getBuilding(buildingID)
				lclist = build.getFloorInfos()
				level = build.level
			} else {
				lclist = this.map.getFloorInfos()
				level = this.map.getLevel()
			}
			const coords = event.coords
			// alert(coords.x + ', y: ' + coords.y)
			const targets = event.targets
			if (coords.x && coords.y && coords.z) {
				const poly = targets.find(item => item.poly)
				const clickMode = event.targets.find(it => it.type === fengmap.FMType.MODEL)
				const floor = lclist.find(it => it.level === level)
				if (poly) {
					const mapCoordinate = fengmap.FMCalculator.mapCoordinateToLatlng(coords)
					this.wz.lon = mapCoordinate.x
					this.wz.lat = mapCoordinate.y
					// alert(`未点击到设备，经度：${mapCoordinate.x}纬度：${mapCoordinate.y}`)
					if (this.markerSz.remove) {
						this.markerSz.remove()
					}
					this.markerSz = new fengmap.FMDomMarker({
						content: `<div class="mybkkd"></div>`,
						x: coords.x,
						y: coords.y
					})
					this.markerSz.addTo(this.floor)
					this.listvisible = true
					this.bindObj.factory = poly.poly
					if (buildingID) {
						this.bindObj.buildingID = buildingID
						if (clickMode) {
							if (clickMode.name == undefined) {
								this.bindObj.buildingName = '室内建筑'
							} else {
								this.bindObj.buildingName = clickMode.name
							}
						} else {
							this.bindObj.buildingName = '室内建筑'
						}
					} else {
						this.bindObj.buildingID = ''
						if (clickMode) {
							if (clickMode.name == undefined) {
								this.bindObj.buildingName = '室外'
							} else {
								this.bindObj.buildingName = clickMode.name
							}
						} else {
							this.bindObj.buildingName = '室外'
						}
					}
					if (floor) {
						this.bindObj.floor = floor.name
					} else {
						this.bindObj.floor = 'F1'
					}
				} else {
					alert('该区域不属于任何厂区')
				}
			} else {
				alert('未点击到地图位置')
			}
		})
		this.map.on('buildingLoaded', e => {
			this.buildingID = e.buildingID
		})
		this.map.on('buildingEntered', e => {
			this.buildingID = e.buildingID
		})
		this.map.on('buildingExited', e => {
			this.buildingID = ''
		})
		this.map.on('loaded', () => {
			const scrollFloorControl = new fengmap.FMToolbar({
				position: fengmap.FMControlPosition.RIGHT_TOP,
				floorButtonCount: 10,
				offset: {
					x: -40,
					y: 20
				},
				viewModeControl: false,
				floorModeControl: true,
				needAllLayerBtn: false
			})
			this.map.setZoom({
				zoom: 16.5
			})
			this.level = this.map.getLevel()
			this.floor = this.map.getFloor(this.level)
			scrollFloorControl.addTo(this.map)
			const polygon0 = new fengmap.FMPolygonMarker({
				height: 0,
				opacity: 0,
				borderWidth: 0,
				points: [
					{ x: 11158079.72043273, y: 2948579.397955249 },
					{ x: 11158640.215603583, y: 2948589.0742090982 },
					{ x: 11158732.204320937, y: 2948083.2219227543 },
					{ x: 11158186.060514834, y: 2948013.859042604 },
					{ x: 11158093.7583406, y: 2948166.279708559 }
				]
			})
			polygon0.addTo(this.floor)
			polygon0.poly = '大理卷烟厂主厂区'
			const polygon1 = new fengmap.FMPolygonMarker({
				height: 0,
				opacity: 0,
				borderWidth: 0,
				points: [
					{ x: 11158535.38175, y: 2947293.954362 },
					{ x: 11159433.517283333, y: 2948168.2033121004 },
					{ x: 11159997.502656601, y: 2947813.6385906935 },
					{ x: 11159118.100382367, y: 2946658.498484091 }
				]
			})
			polygon1.addTo(this.floor)
			polygon1.poly = '大理卷烟厂复烤厂'
			const polygon2 = new fengmap.FMPolygonMarker({
				height: 0,
				opacity: 0,
				borderWidth: 0,
				points: [
					{ x: 11154852.81803647, y: 2949448.13086505 },
					{ x: 11155088.119742846, y: 2949470.257860374 },
					{ x: 11155090.818156911, y: 2949273.8133165194 },
					{ x: 11155069.655538283, y: 2949195.522749098 },
					{ x: 11154893.501531333, y: 2949200.8932588063 }
				]
			})
			polygon2.addTo(this.floor)
			polygon2.poly = '大理卷烟厂斜阳库'
			this.getList()
		})
	},
	methods: {
		directionOnChange(checked) {
			if (checked) {
				this.form.is_direction = 1
			} else {
				this.form.is_direction = -1
			}
		},
		link(url) {
			this.$router.push(url)
		},
		weituo(e) {
			if (e.target.className === 'feng-icon-div') {
				// this.bind_id = e.target.dataset.id
				this.getInfo(e.target.dataset.id)
				this.infovisible = true
				// alert('点中设备，id为：' + e.target.dataset.id)
			} else {
				console.log('点中空白', this.bindObj)
				this.notbindList()
			}
		},
		getInfo(device_id) {
			this.$post(domain + '/admin/v1/device/info', {
				'device_id': device_id
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					this.confirmLoading = false
					if (code == 0) {
						console.log('设备名称', data)
						this.form = data
						this.getTypeList(data.class_type)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		bind(item) {
			let params = new URLSearchParams();
			if (this.bindObj.factory == '大理卷烟厂复烤厂') {
				params.append('factory_class', 1);
			} else if (this.bindObj.factory == '大理卷烟厂主厂区') {
				params.append('factory_class', 2);
			} else if (this.bindObj.factory == '大理卷烟厂斜阳库') {
				params.append('factory_class', 3);
			} else {
				this.$message.error('请选择厂区')
			}
			params.append('device_id', item.device_id);
			params.append('lon', this.wz.lon)
			params.append('lat', this.wz.lat)
			params.append('build_id', this.bindObj.buildingID)
			params.append('build_name', this.bindObj.buildingName)
			params.append('floor_id', this.bindObj.floor)
			// let obj = {
			// 	'device_id': item.device_id,
			// 	'floor_id': this.query.floor_id,
			// 	'lon': this.wz.lon,
			// 	'lat': this.wz.lat
			// }
			this.$post(domain + '/admin/v1/device/deviceBind', params, {
				'content-type': 'application/x-www-form-urlencoded'
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					this.confirmLoading = false
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.notbindList()
						this.getList()
						this.listvisible = false
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		notBind(item) {
			this.$post(domain + '/admin/v1/device/deviceUnBind', {
				'device_id': item.device_id
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.notbindList()
						this.getList()
						this.infovisible = false
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => { })
		},
		listonClose() {
			this.listvisible = false
			this.bindObj = {
				factory: '',
				buildingID: '',
				buildingName: '',
				floor: '',
			}
			// this.listquery = {
			// 	page: 1,
			// 	limit: this.listquery.limit,
			// 	bind_type: 1
			// }
		},
		callback(key) {
			this.listquery.page = 1
			this.notbindList()
		},
		Reset() {
			this.listquery = {
				page: 1,
				limit: this.listquery.limit,
				bind_type: 1
			}
			this.getList()
		},
		toContrastList(id) {
			this.$router.push({
				path: '/contrastList',
				query: {
					id: id,
				}
			})
		},
		hide() {
			this.infovisible = false
		},
		notbindList() {
			console.log('参数打印', this.listquery)
			this.$post(domain + '/admin/v1/device/notBindList', this.listquery).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		warnChange(checked) {
			if (checked) {
				this.form.warn_show = 1
			} else {
				this.form.warn_show = -1
			}
		},
		onlyOnChange(checked) {
			//如果选中
			if (checked) {
				this.form.is_customize = 1
			} else {
				//需要重新渲染一下东西把
				this.$post(domain + '/admin/v1/dictionary/info', {
					'dictionary_id': this.form.dictionary_id
				})
					.then(res => {
						let {
							code,
							msg,
							data
						} = res
						if (code == 0) {
							this.form.warn_show = data.warn_show
							this.form.low_show = data.low_show
							this.form.high_show = data.high_show
						} else {
							this.$message.error(msg, 1.5)
						}
					})
					.catch(err => { })
				this.form.is_customize = -1
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/device/bindList', this.query).then(res => {
				if (res.code === 0) {
					this.markers.map(item => {
						item.remove()
					})
					this.markers = []
					res.data.list.map(item => {
						let p
						if (item.build_id) {
							p = this.map.getBuilding(item.build_id)
						} else {
							p = this.map
						}
						const floorInfo = p.getFloorInfos()
						let level = floorInfo.find(it => it.name === item.floor_id)
						if (!level) {
							level = { level: 1 }
						}
						const floor = p.getFloor(level.level)
						if (Math.floor(item.lat) === 25 && Math.floor(item.lon) === 100) {
							const latlngToMap = fengmap.FMCalculator.latlngToMapCoordinate({
								x: item.lon,
								y: item.lat
							})
							const marker = new fengmap.FMDomMarker({
								content: `<div class="feng-icon-div" data-id="${item.device_id}">
										<img class="img-fengmap-icon" src="${domain}${item.icon}">
									</div>`,
								x: latlngToMap.x,
								y: latlngToMap.y
							})
							// console.log('这里执行了吗？')
							// console.log(marker, this.floor)
							marker.addTo(floor)
							this.markers.push(marker)
						}
					})
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.listquery.page = e.current
			this.notbindList();
		},
		handleChangeFactory() {
			this.factory_id = this.query.factory_class
			switch (this.factory_id) {
				case 2: this.map.setCenter({
					x: 11158376.451710911,
					y: 2948319.80990674
				})
					break
				case 1: this.map.setCenter({
					x: 11159216.246609673,
					y: 2947459.220938715
				})
					break
				case 3: this.map.setCenter({
					x: 11154974.786352161,
					y: 2949316.718100336
				})
			}
			this.getList()
		},
		handleSearch(query) {
			this.getList();
			console.log(query)
		},
		chageLevel() {
			this.getList()
		},
		handleSearchNotBind(query) {
			this.listquery.page = 1
			this.notbindList()
		},
		handleChangexm(e) {
			this.jzlist = [{
				'id': 1,
				'label': '办公室'
			}]
		},
		handleChange() {
			this.notbindList()
		},
		onClose() {
			this.infovisible = false
			this.form = {}
		},
		mainqueryclassChange(e) {
			this.$post(domain + '/admin/v1/dictionary/deviceTypeNameSelect', {
				'class_type': e
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						// this.$message.success(msg, 1.5)
						this.typelsit = data
						delete this.query.device_type_name
						console.log('query', this.query)
						// this.query.device_type_name = ''
						this.getList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => { })
		},
		mainquerytypeChange(e) {
			this.getList()
		},
		handleChangeEdit(value) {
			this.getTypeList(value)
			delete this.form.dictionary_id
		},
		getTypeList(class_type) {
			this.$post(domain + '/admin/v1/dictionary/deviceTypeNameSelect', {
				'class_type': class_type
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						this.typelsit = data
						console.log('typelist', this.typelsit)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => { })
		},
		submitInfo() {
			console.log(this.form)
			this.$refs.dictionaryForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					// let obj = {
					// 	device_name: this.form.device_name,
					// 	lon: this.form.lon,
					// 	lat: this.form.lat,
					// 	remark: this.form.remark,
					// 	device_id: this.device_id
					// }

					this.$post("device/update", this.form)
						.then(res => {
							if (this.form.dictionary_id === '39') {
								this.$post("device/direction", {
									direction: this.form.direction,
									device_id: this.form.device_id,
									is_direction: this.form.is_direction
								})
							}
							let {
								code,
								msg,
								data
							} = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.infovisible = false
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
	},
	computed: {
		isDo: function () {
			return this.form.is_customize == -1;
		}
	}
}
</script>

<style lang="less" scoped>
.full {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: #fff;
	padding: 10px;

	.fengmap-box {
		width: 90vw;
		height: 80vh;
	}
}

.fengmap-box {
	position: relative;
	width: 70vw;
	height: 70vh;
	overflow: hidden;

	#fengmap {
		position: absolute;
		top: 0;
		width: 100%;
		bottom: -50px;
		cursor: crosshair;
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.maps-box {
	.form-search {
		.ant-form-item-label {
			width: auto;
		}
	}

	.form-search.ant-form-inline .ant-form-item {
		margin-right: 20px;
		margin-bottom: 10px;
	}

	.feng-icon-div {
		width: 30px;
		height: 30px;
		overflow: hidden;
		background-size: 100% 100%;
		cursor: pointer;
		border-radius: 50%;
		border: 5px solid #333;
		background-color: #fff;
		box-sizing: content-box;
		margin-left: -5px;
		margin-top: -5px;

		&:hover {
			border-color: #2B6AE0;

			.img-fengmap-icon {
				filter: drop-shadow(#2B6AE0 100px 0);
			}
		}
	}

	.img-fengmap-icon {
		width: 24px;
		height: 24px;
		margin-left: 3px;
		margin-top: 3px;
		filter: drop-shadow(#333 100px 0);
		transform: translateX(-100px);
		pointer-events: none;
		user-select: none;
	}
}

.form-search {

	.ant-input,
	.ant-select,
	.ant-select-selection--single {
		height: 30px;
	}

	.ant-select-selection--single .ant-select-selection__rendered {
		height: 30px;
		line-height: 30px;
	}
}

.mybkkd {
	background-color: #fff;
	height: 30px;
	position: relative;
	width: 2px;
	left: 14px;
}

.mybkkd:after {
	background: #fff;
	content: "";
	height: 2px;
	position: absolute;
	left: -14px;
	top: 14px;
	width: 30px;
}

.slider {
	margin-bottom: 16px;
}

.dir-box {
	left: 25px;
	width: 30px;
	height: 30px;
	position: relative;
	border-radius: 50%;
	background-color: #43DC8A;
}

.img-dir {
	position: absolute;
	left: 5px;
	top: 0;
	width: 50px;
	height: 30px;
	transform-origin: 10px 15px;
}
</style>
